<template>
  <div>

    <v-container  fluid class="px-10 mt-5">
      <HeadCard ico="mdi-home-group" :name='namePage'  @inputSearch="inputSearch"/>
                  <v-card class="mb-3">
        <v-card-title class="py-2">
      <v-row>
        <v-col md="auto">
          <v-autocomplete
            auto-select-first
            dense
            outlined
            label="Семестр"
            v-if="!isZaoch"
            hide-details
            hide-no-data
            :items="semests"
            item-value="id"
            item-text="name"
            return-object
            v-model="selectSem"
            @change="filterData"
          >
          </v-autocomplete>

          <v-autocomplete
            auto-select-first
            dense
            outlined
            label="Сессия"
            v-if="isZaoch"
            hide-details
            hide-no-data
            :items="sessions"
            item-value="id"
            item-text="name"
            return-object
            v-model="selectSess"
            @change="filterData"
          ></v-autocomplete>
        </v-col>    
      </v-row> 
               </v-card-title>
      </v-card>       
      <v-row>
        <v-col>
          <v-card>
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="disciplines"
                :items-per-page="15"
                :search="search"
              >
         <template v-slot:body.append>
        <tr>
          <td></td>
          <td></td>
          <td>
              <b>Всего: {{totalAud}}</b>
          </td>
          <td>
              <b>Всего: {{totalNagr}}</b>
          </td>
          <td>
              <b>Всего: {{total}}</b>
          </td>
          <td colspan="4"></td>
        </tr>
      </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import directory from "@/helpers/api/directory";
const HeadCard = () => import('@/components/HeadCard')

export default {
  name: "Nagr",
  components: {
    HeadCard
  },
  data () {
    return {
      namePage:'',
      disciplines: [],
      disciplinesCopy: [],
      loading: true,
      search: '',
      selectSem:{},
      selectSess:{},
      isZaoch:false,
      totalAud:0,
      totalNagr:0,
      total:0,
      semests:[
          {
            id:-1,
            name:'Все'
          },
          {
            id:1,
            name:'Осенний'
          },
          {
            id:0,
            name:'Весенний'
          },
        ],
      sessions:[
          {
            id:-1,
            name:'Все'
          },
          {
            id:1,
            name:'Установочная'
          },
          {
            id:2,
            name:'Зимняя'
          },
          {
            id:3,
            name:'Летняя'
          },
      ],
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    headers() {
      if(this.prepID==null)
      {
        return [
          { text: 'Дисциплина', value: 'discipline' },
          { text: 'Сессия', value: 'extramuralSession' },
          { text: 'Ауд. ч.', value: 'nagrAud', width:130 },
          { text: 'Др. ч.	', value: 'nagrOther', width:130  },
          { text: 'Итого ч.	', value: 'nagrTotal', width:130  },
          { text: 'Преподаватель', value: 'fioTeacher' },
          { text: 'Вид Занятий	', value: 'typeOfActivitiesFull' },
          { text: 'Вид Контроля', value: 'typeOfControl' },
        ]
      }
      else
      {
        return [
          { text: 'Дисциплина', value: 'discipline' },
          { text: 'Группа', value: 'group' },
          { text: 'Курс', value: 'cours' },
          { text: 'Студентов', value: 'students' },
          { text: 'Сессия', value: 'extramuralSession' },
          { text: 'Ауд. ч.', value: 'nagrAud', width:130  },
          { text: 'Др. ч.	', value: 'nagrOther', width:130  },
          { text: 'Итого ч.	', value: 'nagrTotal', width:130  },
          { text: 'Вид Занятий	', value: 'typeOfActivitiesFull' },
          { text: 'Вид Контроля', value: 'typeOfControl' },
        ]
      }
    },

    groupID () {
      return this.$route.params.groupid ? this.$route.params.groupid : null
    },

    selyear () {
      return this.$route.params.selyear ? this.$route.params.selyear : null
    },

    prepID () {
      return this.$route.params.prepid ? this.$route.params.prepid : null
    },

    kafID () {
      return this.$route.params.kafid ? this.$route.params.kafid : null
    },
  },

  methods: {
    async load () {
      this.loading = true
      var data = await directory.getLoadDisciplines (this.groupID, this.selyear, this.prepID, this.kafID)
      this.disciplines = data.listLoadDisciplines
      this.disciplinesCopy= data.listLoadDisciplines
      this.isZaoch= data.isZaoch

      if(this.selectSem.id == undefined)
        this.selectSem = this.semests[0]

      if(this.selectSess.id == undefined)
        this.selectSess = this.sessions[0]

      this.loading = false
    },
    inputSearch (res) {
      this.search = res
    },
    async init()
    {
      await this.load()
      await this.namePageFunc()
      await this.countTotal()
    },
    namePageFunc(){
      if(this.groupID>0)
      {
        this.namePage = "Учебная нагрузка группы " + this.disciplines[0].group + " на "+this.disciplines[0].year+" год"
      }
      else
      {
        this.namePage = "Преподаватель " + this.disciplines[0].fioTeacher + " учебная нагрузка на "+this.disciplines[0].year+" год"
      }
    },
    filterData(){
        this.disciplines = this.disciplinesCopy

        if (this.IsZaoch && this.selectSess != -1)
            this.disciplines = this.disciplines.filter(f=>f.session==this.selectSess.id)

        if (!this.IsZaoch &&  this.selectSem.id != -1)
            this.disciplines = this.disciplines.filter(f=>f.semester%2==this.selectSem.id)

        this. countTotal()
    },

    countTotal(){
        this.totalAud=0
        this.totalNagr=0
        this.total=0

        for (let i=0; i<this.disciplines.length;i++)
        {
          this.totalAud=this.totalAud+this.disciplines[i].nagrAud
          this.totalNagr=this.totalNagr+this.disciplines[i].nagrOther
          this.total=this.total+this.disciplines[i].nagrTotal
        }
    }
  }
}
</script>

<style scoped>

</style>
