/*
    Методы для работы с API админки
*/


import api from '@/helpers/apiGet'
export default {
    // TODO: Запросы для кафедр, факультетов и т.д.
    async smtpTest (theme, message) {
        let response = await api.post('/api/Admin/SMTPTester', { theme: theme, message: message })
        return response
    },
    async getFaculties () {
        let response = await api.get('/api/faculties')
        return  response
    },
    async getSpecialties () {
        let response = await api.get('/api/Spets')
        return  response.data.listSpets
    }
}