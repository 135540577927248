<template>
  <div style="max-width: 90%;" class="ma-auto mt-3">
    <v-layout style="max-width: 100%;">
      <v-card-title class="pl-3" style="word-break: break-word !important;">
        {{studId}} - список задолженностей студента
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          solo
          class="mb-3 search-field"
          clearable
      ></v-text-field>
    </v-layout>
    <v-slide-x-transition>
        <v-card>
          <v-data-table  :items-per-page="15" :items="items" :loading="loading"  :search="search"  :headers="headers">
                        <template v-slot:item.discipline="{ item }">
              <a :href='`#/Veds/${0}/${item.vedId}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.discipline}}
              </a>
            </template>
          </v-data-table>
        </v-card>
    </v-slide-x-transition>
  </div>
</template>

<script>
import veds from "@/helpers/api/veds";

export default {
  
  name: "DebtByStudent",

  data () {
    return {
      search:"",
      loading:false,
      items:[]
    }
  },
  computed: {
    headers () {
       return [
        {text: 'Дисциплина', value: 'discipline'},
        {text: 'Итоговая оценка', value: 'total'},
        {text: 'Тип контроля', value: 'vedType'},
        {text: 'Преподаватель', value: 'prep'},
      ]

    },

    groupID () {
      return this.$route.params.groupID === undefined ? null : this.$route.params.groupID
    },

    sem () {
      return this.$route.params.sem === undefined ? null : parseInt(this.$route.params.sem)
    },

    studId () {
      return this.$route.params.studId === undefined ? null : this.$route.params.studId
    },
  },

  mounted() {
    this.loadDiscipline()
  },

  methods: {
    async loadDiscipline() {
      this.loading = true     
      this.items = await veds.DebtByStudent(this.groupID,this.sem,this.studId) 
      this.loading = false
    },

  }
}
</script>

<style scoped>

</style>