<template>
  <div>
    <v-btn @click="test">Протестировать</v-btn>
  </div>
</template>

<script>
import commonMixin from "@/mixins/commonMixin";
import admin from "@/helpers/api/admin";
export default {
  name: "SMTP",
  mixins: [commonMixin],
  methods: {
    async test () {
      let result = await admin.smtpTest('test', 'test')
    }
  },
  computed: {
    cuser () {
      return this.$store.getters.cuser
    }
  }
}
</script>

<style scoped>

</style>