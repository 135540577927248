<template>
  <div>
    <span class="mr-2">
    Кафедра:
    </span>
    <v-menu class="ml-2" offset-y max-height="300">
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-bind="attrs" v-on="on" small>
          <span>
            {{value === -1 ? items[0].kafedraShortName: items.filter(f=>f.kafedraID === value)[0].kafedraShortName}}
          </span>
          <v-icon right>mdi-chevron-down</v-icon>
        </v-chip>
      </template>
      <v-list >
        <v-list-item
            @click="$emit('input', item.kafedraID)"
            v-for="(item, index) in items"
            :key="index"
            :disabled="value === item.kafedraID"
        >
          <v-list-item-title>{{ item.kafedraShortName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import directory from "@/helpers/api/directory";

export default {
  name: "FacultyPicker",
  props: {
    value: Number
  },
  data () {
    return {
      items: [
        {
          effContractIgnore: false,
          faculID: 0,
          isDeleted: null,
          kafedraAud: "",
          kafedraID: 0,
          kafedraName: "",
          kafedraNum: 0,
          kafedraPhone: null,
          kafedraShortName: "",
          kafedraZav: ""
        }
      ]
    }
  },

  computed: {
    
  },

  methods: {
    async load () {
      this.items = await directory.getDepartments()
      this.items.unshift(
                {
          effContractIgnore: false,
          faculID: 0,
          isDeleted: null,
          kafedraAud: "",
          kafedraID: -1,
          kafedraName: "",
          kafedraNum: 0,
          kafedraPhone: null,
          kafedraShortName: "Все",
          kafedraZav: ""
        }
      )   
    },

    async init()
    {
      await this.load()
    },
  },

  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>