<template>
  <div>
    <!--<v-data-table :loading="loading" :headers="headers" :items="departments">

    </v-data-table>-->
    <v-container  fluid class="px-10 mt-5">
      <HeadCard ico="mdi-home-group" :name='"Кафедры университета"'  @inputSearch="inputSearch"/>
      <!--<v-row>
        <v-col>
          <h2 align="center" class="py-3  text--primary">Кафедры университета</h2>
        </v-col>
      </v-row>
       <v-row no-gutters>
        <v-col>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
                solo
              ></v-text-field>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col>
          <v-card>
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="departments"
                :items-per-page="15"
                :search="search"
              >
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import directory from "@/helpers/api/directory";
const HeadCard = () => import('@/components/HeadCard')

export default {
  name: "Departments",
  components: {
    HeadCard
  },
  data () {
    return {
      departments: [],
      loading: true,
      search: '',
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    headers() {
      return [
        { text: 'Название', value: 'kafedraName' },
        { text: 'Сокращение', value: 'kafedraShortName' },
        { text: 'Заведующий', value: 'kafedraZav' },
        { text: 'Аудитория', value: 'kafedraAud' },
        { text: 'Телефон', value: 'kafedraPhone' },
      ]
    },

    id () {
      return this.$route.params.id === undefined ? null : this.$route.params.id
    },
  },
  methods: {
    async load () {
      this.loading = true
      if(this.id!=null)
      {
        this.departments = await directory.getDepartments()
        this.departments = this.departments.filter(f=>f.kafedraID==this.id)
      }
      else
      {
        this.departments = await directory.getDepartments()
      }
      this.loading = false
    },
    inputSearch (res) {
      this.search = res
    }
  }
}
</script>

<style scoped>

</style>
