<template>
  <div>
    <!--<v-data-table :loading="loading" :headers="headers" :items="departments">

    </v-data-table>-->
    <v-container  fluid class="px-10 mt-5">
      <HeadCard ico="mdi-table-arrow-down" :name='"Учебная нагрузка преподавателей"'  @inputSearch="inputSearch"/>
           <v-card class="mb-3">
        <v-card-title class="py-2">
              <v-btn-toggle
                v-model="mode"
                mandatory
                color="primary"
                dense
                @change="load"
              >
                <v-btn small>
                  <v-icon class="mr-1" small color="primary">mdi-account-multiple </v-icon> По группе
                </v-btn>
                <v-btn small>
                  <v-icon class="mr-1" small color="primary">mdi-clipboard-account </v-icon> По преподавателю
                </v-btn>
              </v-btn-toggle>
          <v-spacer></v-spacer>
          <v-autocomplete
            auto-select-first
            dense
            outlined
            label="Факультет"
            v-if="!mode"
            hide-details
            hide-no-data
            :items="faculties"
            item-value="facultyID"
            item-text="facultyName"
            return-object
            v-model="selectFaculty"
            @change="load"
          >
          </v-autocomplete>
          <v-autocomplete
            auto-select-first
            dense
            outlined
            label="Кафедра"
            v-if="mode"
            hide-details
            hide-no-data
            :items="kafs"
            item-value="kafedraID"
            item-text="kafedraName"
            return-object
            v-model="selectKaf"
             @change="load"
          ></v-autocomplete>
          <div style="max-width:200px;">
          <v-autocomplete
            auto-select-first
            dense
            outlined
            label="Учебный год"
            hide-details
            hide-no-data
            class="ml-2"
            :items="years"   
            v-model="year" 
             @change="load"   
          ></v-autocomplete>
          </div>
         </v-card-title>
      </v-card>
      <v-row>
        <v-col>
          <v-card>
              <v-data-table 
                :loading="loading" 
                :headers="headers" 
                :items="items"
                :items-per-page="15"
                :search="search"
              >
                    <template v-slot:item.name="{ item }">
                      <a style="white-space: nowrap;" :href='`#/Directory/Nagr/${item.groupID}/${year}`'>
                        <v-icon left>mdi-link</v-icon>
                        {{item.name}}
                      </a>
                    </template>
                    <template v-slot:item.fio="{ item }">
                      <a style="white-space: nowrap;" :href='`#/Directory/Nagr/${null}/${year}/${item.teacherId}/${item.kafId}`'>
                        <v-icon left>mdi-link</v-icon>
                        {{item.fio}}
                      </a>
                    </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  
</template>

<script>
import directory from "@/helpers/api/directory";
const HeadCard = () => import('@/components/HeadCard')

export default {
  name: "Teachers",
  components: {
    HeadCard
  },
  data () {
    return {
      departments: [],
      loading: true,
      search: '',
      mode: 0,
      faculties:[],
      kafs:[],
      selectFaculty:{},
      selectKaf:{},
      year:'',
      items:[]
    }
  },

  mounted() {
      this.init()
  },

  computed: {
    headers() {
              if(!this.mode)
              {
                return [
                  { text: 'Группа', value: 'name' },
                  { text: 'Курс', value: 'course' },
                  { text: 'Специальность', value: 'speciality' },
                  { text: 'Студентов', value: 'total' },
                ]
              }
              else
              {
                return [
                  { text: 'Преподаватель', value: 'fio', align:'center' },
                ]
              }
    },

    years(){
      return  [new Date().getFullYear()-1 + "-" + new Date().getFullYear(), new Date().getFullYear() + "-" + (new Date().getFullYear()+1), (new Date().getFullYear()+1) + "-" + (new Date().getFullYear()+2)]
    },

  },

  methods: {
    async load () {
      this.loading = true
      if(this.year === '') this.year = this.years[1]
      if(!this.mode)
      {
        var data = await directory.getTeachingLoadOfTeachers(this.selectFaculty.facultyID, this.year)
        this.items = data.listGroup
      }
      else
      {
        var data = await directory.getTeachingLoadOfTeachersFIO(this.selectKaf.kafedraID, this.year)
        this.items = data.listGroup
      }
      this.loading = false
    },
    inputSearch (res) {
      this.search = res
    },
    async getFaculties(){
      this.faculties = await directory.getFaculties()
    },
    async getKafs(){
      this.kafs = await directory.getDepartments()
    },
    async init()
    {
      await this.getFaculties()
      await this.getKafs()
      await this.load()
    }

  }
}
</script>

<style scoped>

</style>