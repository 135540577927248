<template>
  <div>
    <!--<v-data-table :loading="loading" disable-pagination hide-default-footer :headers="headers" :items="faculties">
    </v-data-table>-->
    <v-container  fluid class="px-10 mt-5">
      <HeadCard ico="mdi-book-education" :name='"Факультеты университета"'  @inputSearch="inputSearch"/>
      <!--<v-row>
        <v-col>
          <h2 align="center" class="py-3  text--primary">Факультеты университета</h2>
        </v-col>
      </v-row>
       <v-row no-gutters>
        <v-col>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
                solo
              ></v-text-field>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col>
          <v-card>
              <v-data-table 
                :loading="loading" 
                :headers="headers" 
                :items="faculties"
                :items-per-page="15"
                :search="search"
              >
                <template v-slot:item.facultyName="{ item }">
                  <a :href='`#/Directory/Groups/byFaculties/${item.facultyID}`'>
                    <v-icon left>mdi-link</v-icon>
                    {{item.facultyName}}
                  </a>
                </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import directory from "@/helpers/api/directory";
const HeadCard = () => import('@/components/HeadCard')

export default {
  name: "Faculties",
    components: {
    HeadCard
  },
  data () {
    return {
      faculties: [],
      loading: true,
      search: '',
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    headers() {
      return [
        { text: 'Название', value: 'facultyName' },
        { text: 'Сокращение', value: 'facultyShortName' },
        { text: 'Декан', value: 'dekan' },
        { text: 'Аудитория', value: 'aud' },
        { text: 'Телефон', value: 'phoneNumber' },
      ]
    },

        id () {
      return this.$route.params.id === undefined ? null : this.$route.params.id
    },
  },
  methods: {
    async load () {
      this.loading = true
      if(this.id!=null)
      {
          this.faculties = await directory.getFaculties()
          this.faculties = this.faculties.filter(f=>f.facultyID == this.id)
      }
      else
      {
        this.faculties = await directory.getFaculties()
      }

      this.loading = false
    },
    inputSearch (res) {
      this.search = res
    }
  }
}
</script>

<style scoped>

</style>