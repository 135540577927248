<template>
  <div>
    <v-container fluid class="px-10 mt-5">
      <HeadCard ico="mdi-account-multiple" :name='"Группы "+ pageTitle'  @inputSearch="inputSearch"/>
      <!--<v-row>
        <v-col>
          <h2 align="center" class="py-3  text--primary">Группы {{pageTitle}}</h2>
        </v-col>
      </v-row>
       <v-row no-gutters>
        <v-col>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
                solo
              ></v-text-field>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col>
          <v-card>
              <v-data-table 
                :loading="loading" 
                :headers="headers" 
                :items="groups"
                :items-per-page="15"
                :search="search"
              >
                    <template v-slot:item.groupName="{ item }">
                      <a style="white-space: nowrap;" :href='`#/Directory/Students/${item.groupID}`'>
                        <v-icon left>mdi-link</v-icon>
                        {{item.groupName}}
                      </a>
                    </template>
                    <template v-slot:item.specialtyName="{ item }">
                        <v-icon left>
                          mdi-link
                        </v-icon>
                        <span>{{ item.specialtyName}}</span>
                    </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import directory from "@/helpers/api/directory";
const HeadCard = () => import('@/components/HeadCard')

export default {
  name: "Groups",
  components: {
    HeadCard
  },
  data () {
    return {
      groups:[],
      pageName:"",
      search: '',
      loading:false,
      groupName:"",
      specialtyName:"",
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    headers() {
      return [
        { text: 'Группа', value: 'groupName', width:"100" },
        { text: 'Курс', value: 'course' },
        { text: 'Форма обучения', value: 'form', width:"200" },
        { text: 'Направления/специальности', value: 'speciality' },
        { text: 'Всего', value: 'countStud' },
        { text: 'ОО', value: 'oo' },
        { text: 'ЦН', value: 'cn' },
        { text: 'СН', value: 'sn' },
        { text: 'ОП', value: 'op' },
        { text: 'Учебный план', value: 'planName', width:"300" },
      ]
    },

    mode () {
      return this.$route.params.mode === undefined ? null : this.$route.params.mode
    },

    id () {
      return this.$route.params.id === undefined ? null : this.$route.params.id
    },
    pageTitle () {
        return '' //название не успевает прогружаться в спец. и факул. из=за этого не отображается таблица
        //!this.mode || this.groups.length === 0 ? 'университета' : ( this.mode === 'bySpecialties' ? 'специальности '+this.group[0].specialtyName : 'факультета '+this.group[0].faculty.facultyShortName )
    },
    facultyID () {
      return this.mode === 'byFaculties' ? this.id : null
    },
    specialtyID () {
      return this.mode === 'bySpecialties' ? this.id : null
    }
  },
  methods: {
    async load () {
      this.loading = true
      this.groups = await directory.getGroups (this.facultyID, this.specialtyID,null);
      this.loading = false
    },
    inputSearch (res) {
      this.search = res
    }
  }
}
</script>

<style scoped>

</style>