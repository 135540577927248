/*
    Общие миксины которые могут понадобится в большинстве случаев
*/

export default {
    methods: {
        log (message) {
            console.log(message)
        }
    },
    computed: {
        isDebug () {
            return process.env.NODE_ENV === 'development'
        },
        isBeta () {
            return localStorage.getItem('beta') === '1'
        }
    }
}