var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-auto mt-3",staticStyle:{"max-width":"90%"}},[_c('v-layout',{staticStyle:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"pl-3",staticStyle:{"word-break":"break-word !important"}},[_vm._v(" Статистика задолженностей ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mb-3 search-field",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":"","solo":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',[_c('v-layout',{staticClass:"mb-3"},[_c('v-card',{staticClass:"pa-3",staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"md":"auto"}},[_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v(" Статистика по: ")]),_c('v-menu',{staticClass:"ml-2",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":""}},'v-chip',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(_vm.typeStatic==0?"студентам":"преподавателям")+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[_c('v-list-item-group',{model:{value:(_vm.typeStatic),callback:function ($$v) {_vm.typeStatic=$$v},expression:"typeStatic"}},[_c('v-list-item',{attrs:{"value":"0"},on:{"click":function($event){(_vm.typeStatic=0), _vm.load()}}},[_c('v-list-item-title',[_vm._v("По студентам")])],1),_c('v-list-item',{attrs:{"value":"1"},on:{"click":function($event){(_vm.typeStatic=1), _vm.load()}}},[_c('v-list-item-title',[_vm._v("По преподавателям")])],1)],1)],1)],1)],1)]),_c('v-col',{attrs:{"md":"auto"}},[_c('faculty-picker',{on:{"input":_vm.load},model:{value:(_vm.facId),callback:function ($$v) {_vm.facId=$$v},expression:"facId"}})],1),_c('v-col',{attrs:{"md":"auto"}},[_c('year-picker',{on:{"input":_vm.load},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('v-col',{attrs:{"md":"auto"}},[_c('sem-picker',{on:{"input":_vm.load},model:{value:(_vm.sem),callback:function ($$v) {_vm.sem=$$v},expression:"sem"}})],1)],1)],1)],1)],1),(_vm.typeStatic==1)?_c('div',[_c('v-layout',{staticClass:"mb-3"},[_c('v-card',{staticClass:"pa-3",staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"md":"auto"}},[_c('kaf-picker',{on:{"input":_vm.load},model:{value:(_vm.kafId),callback:function ($$v) {_vm.kafId=$$v},expression:"kafId"}})],1),_c('v-col',{attrs:{"md":"auto"}},[_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v(" Сортировать по: ")]),_c('v-menu',{staticClass:"ml-2",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":""}},'v-chip',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(_vm.sortItems.filter(function (f){ return f.value==_vm.sortType; })[0].name)+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,940790547)},[_c('v-list',[_c('v-list-item-group',{model:{value:(_vm.sortType),callback:function ($$v) {_vm.sortType=$$v},expression:"sortType"}},_vm._l((_vm.sortItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"value":item.value,"disabled":_vm.sortType === item},on:{"click":function($event){(_vm.sortType=item.value), _vm.load()}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1)],1)])],1)],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"items-per-page":15,"search":_vm.search},scopedSlots:_vm._u([{key:"item.всего",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("#/Directory/Students/" + (item.код_Группы))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.всего)+" ")],1)]}},{key:"item.должников",fn:function(ref){
var item = ref.item;
return [(_vm.typeStatic!=1 & item.должников!=null)?_c('a',{attrs:{"href":("#/Stat/StudentsDebts/" + (item.код_Группы) + "/" + _vm.sem + "/")}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.должников)+" ")],1):_c('span',[_vm._v(_vm._s(item.должников))])]}},{key:"item.должников2",fn:function(ref){
var item = ref.item;
return [(item.должников2!=null)?_c('a',{attrs:{"href":("#/Stat/StudentsDebts/" + (item.код_Группы) + "/" + _vm.sem + "/" + (2))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.должников2)+" ")],1):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }