<template>
  <div style="max-width: 90%;" class="ma-auto mt-3">
        <v-layout style="max-width: 100%;">
      <v-card-title class="pl-3" style="word-break: break-word !important;">
        Статистика задолженностей
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          solo
          class="mb-3 search-field"
          clearable
      ></v-text-field>
    </v-layout>
      <div >
        <v-layout  class="mb-3">
          <v-card style="width: 100%;" class="pa-3">
            <v-row>
              <v-col md="auto">
                <div>
                  <span class="mr-2">
                  Статистика по:
                  </span>
                  <v-menu class="ml-2" offset-y >                   
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" small>
                        <span>
                          {{typeStatic==0?"студентам":"преподавателям"}}
                        </span>
                        <v-icon right>mdi-chevron-down</v-icon>
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item-group v-model="typeStatic">
                      <v-list-item value="0" @click="(typeStatic=0), load()">
                        <v-list-item-title>По студентам</v-list-item-title>
                      </v-list-item>
                      <v-list-item value="1" @click="(typeStatic=1), load()">
                        <v-list-item-title>По преподавателям</v-list-item-title>
                      </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col md="auto"><faculty-picker v-model="facId" v-on:input="load"></faculty-picker>  </v-col>
              <v-col md="auto"><year-picker v-model="year" v-on:input="load"></year-picker> </v-col> 
              <v-col md="auto"><sem-picker v-model="sem" v-on:input="load"></sem-picker>  </v-col>
            </v-row>                       
          </v-card>
        </v-layout>
  </div>
   <div v-if="typeStatic==1">
        <v-layout  class="mb-3">
          <v-card style="width: 100%;" class="pa-3">
            <v-row>
              <v-col md="auto"><kaf-picker v-model="kafId" v-on:input="load"></kaf-picker>  </v-col>
              <v-col md="auto">
                <div>
                  <span class="mr-2">
                  Сортировать по:
                  </span>
                  <v-menu class="ml-2" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" small>
                        <span>
                          {{sortItems.filter(f=>f.value==sortType)[0].name}}
                        </span>
                        <v-icon right>mdi-chevron-down</v-icon>
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item-group v-model="sortType"> 
                      <v-list-item :value="item.value" 
                      v-for="(item, index) in sortItems"
                      :key="index"
                      :disabled="sortType === item"
                      @click="(sortType=item.value), load()"
                      >
                        <v-list-item-title>{{item.name}}</v-list-item-title>
                      </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>                       
          </v-card>
        </v-layout>
  </div>
        <v-row>
        <v-col>
          <v-card>
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="items"
                :items-per-page="15"
                :search="search"
              >
                <template v-slot:item.всего="{ item }">
                  <a :href='`#/Directory/Students/${item.код_Группы}`'>
                    <v-icon left>mdi-link</v-icon>
                    {{item.всего}}
                  </a>
                </template>
                <template v-slot:item.должников="{ item }">
                  <a :href='`#/Stat/StudentsDebts/${item.код_Группы}/${sem}/`' v-if="typeStatic!=1 & item.должников!=null">
                    <v-icon left>mdi-link</v-icon>
                    {{item.должников}}
                  </a>
                  <span v-else>{{item.должников}}</span>
                </template>
                <template v-slot:item.должников2="{ item }">
                  <a :href='`#/Stat/StudentsDebts/${item.код_Группы}/${sem}/${2}`' v-if="item.должников2!=null">
                    <v-icon left>mdi-link</v-icon>
                    {{item.должников2}}
                  </a>
                </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import YearPicker from "@/components/YearPicker";
import SemPicker from "@/components/SemPicker";
import FacultyPicker from "@/components/FacultyPicker";
import KafPicker from "@/components/KafPicker";
import common from "../../helpers/common";
import veds from "@/helpers/api/veds";

export default {
  name: "Debts",
  components: {YearPicker, SemPicker,FacultyPicker, KafPicker},
  data () {
    return {
      search:"",
      loading: true,
      typeStatic:"0",
      year: common.getCurrentYear(),
      sem:common.getCurrentSem(),
      facId:-1,
      kafId:-1,
      sortType:0,
      sortItems:[
        {
          value:0,
          name:"факультету"
        },
        {
          value:1,
          name:"кафедре"
        },
        {
          value:2,
          name:"дисциплине"
        },
        {
          value:3,
          name:"преподавателям"
        },
        {
          value:4,
          name:"кол-ву должников"
        },
      ],
      items:[],
    }
  },

  computed: {
    headers() {
      if(this.typeStatic!=1)
      {
        return [
          { text: 'Курс', value: 'курс' },
          { text: 'Группа', value: 'название' },
          { text: 'Всего', value: 'всего' },
          { text: 'Должников', value: 'должников' },
          { text: 'Более 2 долгов', value: 'должников2' },
        ]
      }
      else
      {
        return [
          { text: 'Дисциплина', value: 'дисциплина' },
          { text: 'Преподаватель', value: 'преподаватель' },
          { text: 'Курс', value: 'курс' },
          { text: 'Должников', value: 'должников' },
        ]
      }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    async load(){ 
      this.loading = true;
      if(this.sem == 1)
      {
          this.items = await veds.DebtStatistics(this.typeStatic,this.facId,this.year,1,this.kafId,this.sortType)
      }
      else
      {
        this.items = await veds.DebtStatistics(this.typeStatic,this.facId,this.year,2,this.kafId,this.sortType)
      }
      
      this.loading = false;
    }
  },
}

</script>

<style scoped>

</style>