<template>
  <div>
    <v-container fluid class="px-10 mt-5">
      <HeadCard ico="mdi-school" :name='"Специальности университета"'  @inputSearch="inputSearch"/>
      <!--<v-row>
        <v-col>
          <h2 align="center" class="py-3  text--primary">Специальности университета</h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
              solo
          ></v-text-field>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col>
          <v-card>
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="specialties"
                :items-per-page="15"
                :search="search"
            >
              <template v-slot:item.specialtyShort="{ item }">
                <a :href='`#/Directory/Groups/bySpecialties/${item.specialtyID}`'>
                  <v-icon left>mdi-link</v-icon>
                  {{item.specialtyShort}}
                </a>
              </template>
              <template v-slot:item.facul.facultyShortName="{ item }">
                <a style="white-space: nowrap;" :href='`#/Directory/Groups/byFaculties/${item.faculID}`'>
                  <v-icon left>mdi-link</v-icon>
                  {{item.facul.facultyShortName}}
                </a>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import directory from "@/helpers/api/directory";
const HeadCard = () => import('@/components/HeadCard')

export default {
  name: "Specialties",
  components: {
    HeadCard
  },
  data() {
    return {
      specialties: [],
      loading: true,
      search: '',
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    headers() {
      return [
        {text: 'Специальность', value: 'specialtyShort'},
        {text: 'Факультет', value: 'facul.facultyShortName'},
        {text: 'Направления/специальности', value: 'specialtyName'},
        {text: 'Шифр', value: 'shifr'},
        {text: 'Квалификация', value: 'qualification'},
      ]
    }
  },
  methods: {
    async load() {
      this.loading = true
      this.specialties = await directory.getSpecialties();
      this.loading = false
    },
    inputSearch (res) {
      this.search = res
    }
  }
}
</script>

<style scoped>

</style>