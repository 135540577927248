<template>
  <div>
    <span class="mr-2">
    Факультет:
    </span>
    <v-menu class="ml-2" offset-y max-height="300">
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-bind="attrs" v-on="on" small>
          <span>
            {{value === -1 ? items[0].facultyShortName : items.filter(f=>f.facultyID === value)[0].facultyShortName}}
          </span>
          <v-icon right>mdi-chevron-down</v-icon>
        </v-chip>
      </template>
      <v-list >
        <v-list-item
            @click="$emit('input', item.facultyID)"
            v-for="(item, index) in items"
            :key="index"
            :disabled="value === item.facultyID"
        >
          <v-list-item-title>{{ item.facultyShortName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import directory from "@/helpers/api/directory";

export default {
  name: "FacultyPicker",
  props: {
    value: Number
  },
  data () {
    return {
      items: [
        {
          aud: "",
          certEnabled: true,
          certInProcessing: 0,
          certIndex: "",
          certMinNumber: null,
          certStudInProcessing: 0,
          dekan: "",
          facultyID: 0,
          facultyName: "",
          facultyShortName: "",
          isDelete: true,
          number: 0,
          phoneNumber: "",
          signature3: "",
          site: true,
          teacherDekanID: 0,
          typeStudy: 0
        }
      ]
    }
  },

  computed: {
    
  },

  methods: {
    async load () {
      this.items = await directory.getFaculties()       
       this.items.unshift(
                 {
          aud: "",
          certEnabled: true,
          certInProcessing: 0,
          certIndex: "",
          certMinNumber: null,
          certStudInProcessing: 0,
          dekan: "",
          facultyID: -1,
          facultyName: "",
          facultyShortName: "Все",
          isDelete: true,
          number: 0,
          phoneNumber: "",
          signature3: "",
          site: true,
          teacherDekanID: 0,
          typeStudy: 0
        }
       )
    },

    async init()
    {
      await this.load()
    },
  },

  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>