<template>
  <!--<div>
    <v-container>
      <v-row>
        <v-col>
          <h2 align="center" class="py-3  text--primary">Студенты группы {{groupName}}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
            <v-card
              class="mx-auto mb-3 px-2 py-2"
               elevation="2"
            >
            <v-row>
            <v-col>
              <v-btn-toggle
                v-model="toggle_exclusive"
                mandatory
                color="primary"
                dense
              >
                <v-btn>
                  <v-icon class="mr-1" color="orange lighten-1">mdi-leaf </v-icon> Осенний семестр
                </v-btn>
                <v-btn>
                  <v-icon class="mr-1" color="green lighten-1">mdi-flower-tulip</v-icon> Весениий семестр
                </v-btn>
              </v-btn-toggle>
              </v-col>
                      <v-col md="auto">
            <v-btn elevation="0">График</v-btn>
        </v-col>
        <v-col md="auto">
            <v-btn elevation="0">Ведомости</v-btn>
        </v-col>
        <v-col md="auto">
            <v-btn elevation="0">Расисание</v-btn>
        </v-col>
        </v-row>
            </v-card>
        </v-col>
      </v-row>
      <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          solo
          class="mb-3"
      ></v-text-field>
      <v-card>
          <v-data-table
              disable-pagination
            :loading="loading"
            :headers="headers"
            :items="students"
            hide-default-footer
            :items-per-page="11"
            :search="search"
          >
          </v-data-table>
      </v-card>
    </v-container>
  </div>-->

   <div>
    <v-container
      fill-height
      container--fluid
      grid-list-xl
    >
      <v-layout justify-center wrap>
        <v-fade-transition>
        <v-flex xs12 md4 >
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 5 : 3" >
              <div >
                <v-card-text class="text-xs-center" :v-if="loading">
                  <div class="mt-1 mb-3" align="center">
                    <span class="font-weight-light"  style="font-size: 20pt">Группа {{groupInfo.group.item1}}</span>
                  </div>
                  <v-divider class="mt-3 mb-3"></v-divider>
                  <div style="display: flex; width: 100%; justify-content: space-between;" class="mb-2">
                    <div class="v-data-table__mobile-row__header">План:</div>
                    <div class="v-data-table__mobile-row__cell"><a style="white-space: nowrap;" :href='`#/Plans/${groupInfo.plan.item2}`'>                   
                    <v-icon left>mdi-link</v-icon>
                    {{groupInfo.plan.item1}}
                    </a></div>
                  </div>
                  <div style="display: flex; width: 100%; justify-content: space-between;" class="mb-2">
                  <div class="v-data-table__mobile-row__header">Специальность:</div>
                  <div class="v-data-table__mobile-row__cell">{{groupInfo.specialName}}</div>
                </div>
                  <div style="display: flex; width: 100%; justify-content: space-between;" class="mb-2">
                    <div class="v-data-table__mobile-row__header">Уровень образования:</div>
                    <div class="v-data-table__mobile-row__cell">{{groupInfo.levelName}}</div>
                  </div>
                  <div style="display: flex; width: 100%; justify-content: space-between;" class="mb-2">
                    <div class="v-data-table__mobile-row__header">Форма обучения:</div>
                    <div class="v-data-table__mobile-row__cell">{{groupInfo.formName}}</div>
                  </div>
                  <div style="display: flex; width: 100%; justify-content: space-between;" class="mb-2">
                    <div class="v-data-table__mobile-row__header">{{groupInfo.labelFaculName}}:</div>
                    <div class="v-data-table__mobile-row__cell">{{groupInfo.faculName}}</div>
                  </div>
                  <div style="display: flex; width: 100%; justify-content: space-between;" class="mb-2">
                    <div class="v-data-table__mobile-row__header">Кафедра:</div>
                    <div class="v-data-table__mobile-row__cell">{{groupInfo.kafName}}</div>
                  </div>
                  <div style="display: flex; width: 100%; justify-content: space-between;">
                    <div class="v-data-table__mobile-row__header">Учебный год:</div>
                    <div class="v-data-table__mobile-row__cell">{{groupInfo.groupYear}}</div>
                  </div>
                  <v-divider class="mt-1 mb-3"></v-divider>
                  <div class="text-left">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip color="primary" class="mb-1 mr-1" :text-color="$vuetify.theme.dark ? 'white': 'white'" v-bind="attrs"  v-on="on">
                          Расписание
                        </v-chip>
                      </template>
                      <v-list>
                        <v-list-item dense>
                          <v-list-item-title ><v-btn text small><v-icon class="mr-1" color="orange lighten-1">mdi-leaf </v-icon> Осенний семестр</v-btn></v-list-item-title>
                        </v-list-item>
                        <v-list-item dense>
                          <v-list-item-title><v-btn text small><v-icon class="mr-1" color="green lighten-1">mdi-flower-tulip</v-icon> Весениий семестр</v-btn></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip color="primary" class="mb-1 mr-1" :text-color="$vuetify.theme.dark ? 'white': 'white'"  v-bind="attrs"   v-on="on" >
                          Ведомости
                        </v-chip>
                      </template>
                      <v-list>
                        <v-list-item dense>
                          <v-list-item-title ><v-btn text small><v-icon class="mr-1" color="orange lighten-1">mdi-leaf </v-icon> Осенний семестр</v-btn></v-list-item-title>
                        </v-list-item>
                        <v-list-item dense>
                          <v-list-item-title><v-btn text small><v-icon class="mr-1" color="green lighten-1">mdi-flower-tulip</v-icon> Весениий семестр</v-btn></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip color="primary" class="mb-1 mr-1" :text-color="$vuetify.theme.dark ? 'white': 'white'"   v-bind="attrs"     v-on="on">
                          График
                        </v-chip>
                      </template>
                      <v-list>
                        <v-list-item dense>
                          <v-list-item-title ><v-btn text small><v-icon class="mr-1" color="orange lighten-1">mdi-leaf </v-icon> Осенний семестр</v-btn></v-list-item-title>
                        </v-list-item>
                        <v-list-item dense>
                          <v-list-item-title><v-btn text small><v-icon class="mr-1" color="green lighten-1">mdi-flower-tulip</v-icon> Весениий семестр</v-btn></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </v-hover>
        </v-flex>
        </v-fade-transition>
        <v-flex xs12 md8 >
          <v-fade-transition>
              <v-card>
                <v-card-title class="py-0">
                   <v-icon class="mr-2">mdi-format-list-bulleted</v-icon> 
                  Список обучающихся
                  <v-spacer></v-spacer>
                  <v-text-field
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          class="mb-3"
                  ></v-text-field>
                </v-card-title>
                <v-divider></v-divider>
                    <v-data-table
              disable-pagination
            :loading="loading"
            :headers="headers"
            :items="students"
            hide-default-footer
            :items-per-page="11"
            :search="search"
          >
          </v-data-table>
              </v-card>

          </v-fade-transition>

        </v-flex>
      </v-layout>
    </v-container>
  </div>
  
</template>

<script>
import directory from "@/helpers/api/directory";

export default {
  name: "Students",
  data () {
    return {
      students: [],
      loading: true,
      search: '',
      toggle_exclusive: undefined,
      groupInfo:{},

    }
  },
  mounted() {
    this.load()
  },
  computed: {
    isEmployee () {
      return this.$store.getters.isEmployee
    },
    headers() {
      return [
        { text: 'Номер зачетной книжки', value: 'recordbook', width:"33%" },
        { text: 'ФИО', value: 'fio', width:"33%" },
        { text: 'Группа', value: 'groupName', align:"center", width:"33%"},
      ].filter(f=> this.isEmployee || f.value !== 'fio')
    },
    groupID () {
      return this.$route.params.groupID ? this.$route.params.groupID : null
    },

  },
  methods: {
    async load () {
      this.loading = true
      this.students = await directory.getStudents(this.groupID)
      this.groupInfo = await directory.getGroupInfo(this.groupID)   
      this.loading = false
    }
  }
}
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
.DataGridExportKab {
  font-size: 11pt !important;
}
.dx-texteditor-input {
  padding-left: 20px !important;
}
td[role="columnheader"] {
  text-align: center !important;
}
.marginBlock {
  margin: 75px auto 0 !important;
}
.classSwitch .v-label{
  margin-bottom: 0px;
}
.snackbarInfo .v-snack__content{
  height: auto !important;
}
.setColorTabsDark{
  background-color: #303030!important;
}
</style>